import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const artisteService = {
    get,
    getById,
    create,
    update,
    findAlbum,
    getbyemail

};


async function getById(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/artistes/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function getbyemail(email) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/artistes/email/${email}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function get() {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/artistes`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function findAlbum(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/artistes/${id}/album`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function create(events) {
    try {
        let ret = await axiosInstance.post(`${baseUrl}/artistes`, events);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function update(events, id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/artistes/${id}`, events);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}
