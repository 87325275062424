import { baseUrl } from "../constants/config";
import moment from 'moment';
import { cryptUtil } from "./crypt.util";

const { format } = require('number-currency-format-2');

export const customFunction = {
    genPass,
    getFilePath,
    saveDateFormat,
    storeParam,
    destoreParam,
    currencyFormate,
    pathCrypte,
    pathDecrypt, 
    backUrl,
    verifAdmin,
    verifArtiste
};

function genPass() {
    return genererPassWord();
}

function genererPassWord() {
    // let passGene = shuffle(randPassword);
    // return passGene.join("").split("", 6).join("");
}

// function shuffle(array) {
//     let currentIndex = array.length, randomIndex;

//     while (currentIndex != 0) {
//         randomIndex = Math.floor(Math.random() * currentIndex);
//         currentIndex--;
//         [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
//     }

//     return array;
// }

function getFilePath(path) {
    return path != null && path != '' ? `${baseUrl}/load?filename=${path}` : '';
}

function saveDateFormat(date) {
    return moment(date).format('YYYY-MM-DD');
}

function destoreParam(path) {
    const p = localStorage.getItem(path != null ? path : 'pathParam');
    return p != null ? JSON.parse(cryptUtil.decrypt(p, 'yendi-path-encrypt')) : null;
}

function storeParam(item, path) {
    localStorage.setItem(path != null ? path :'pathParam', cryptUtil.encrypt(JSON.stringify(item), 'yendi-path-encrypt'));
}

function currencyFormate(amount) {
    return format(amount, {
        currency: '',
        thousandSeparator: '.'
    });
}


function pathCrypte(item) {
    return cryptUtil.encrypt(item, 'deal-path-item-crypt');
}

function pathDecrypt(item) {
    return cryptUtil.decrypt(item, 'deal-path-item-crypt');
}

function backUrl(operationId) {
    return `${location.origin}/paiement/${operationId}`;
}

function verifAdmin() {
    const user = customFunction.destoreParam('yendiuthenti');
    const check = user != null && user.roles.filter(e => e.name.includes('SUPER_AD'));
    return check.length > 0;
}


function verifArtiste() {
    const user = customFunction.destoreParam('yendiuthenti');
    const check = user != null && user.roles.filter(e => e.name.includes('ARTISTE'));
    return check.length > 0;
}