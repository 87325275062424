import { baseUrl } from '../../constants/config';
import { artisteService } from '../../services/artiste.service';
import { customFunction } from '../../utils/customFunction';
import { authHeader } from './auth-header';

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password, typeId) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username : email, password, typeId })
    };

    return fetch(`${baseUrl}/users/auth/signin`, requestOptions)
        .then(handleResponse)
        .then(async (user) => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if (user.roles.filter(e => e.name.toLowerCase().includes('arti'))) {
                    const artiste = await artisteService.getbyemail(user.email);
                    customFunction.storeParam(artiste, 'yendiuartiste');
                }
                customFunction.storeParam(user, 'yendiuthenti');
                //localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('yendiuthenti');
    localStorage.removeItem('yendiuartiste');
    //localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${baseUrl}/users/auth/signup`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
