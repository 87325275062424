import axios from 'axios';
import errorHandler from '../handler/erroHandler';
// import { authService } from '../auth.service';
import { customFunction } from '../../utils/customFunction';

const axiosInstance = axios.create({
    // baseURL: baseUrl
})

axiosInstance.defaults.headers.common['Access-Control-Max-Age'] = "600";


axiosInstance.interceptors.request.use(function (request) {
    if (!request) {
        request = {};
    }
    if (!request.headers) {
        request.headers = {};
    }
    //console.info(request.url)
    let user = customFunction.destoreParam('yendiuthenti');
    if (request.url !== '/users/auth/signin' && user != null) {
        request.headers.Authorization = `Bearer ${user.token}`;
    }
    return request;

}, function (error) {
    errorHandler(error);
});

axiosInstance.interceptors.response.use(function (response) {

    return response;

}, function (error) {
    errorHandler(error);
});


export default axiosInstance;
