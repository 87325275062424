import { customFunction } from "../../utils/customFunction";

export function authHeader() {
    // return authorization header with jwt token
    let user = customFunction.destoreParam('yendiuthenti'); //JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
        return { 'Authorization': 'Bearer ' + user.accessToken };
    } else {
        return {};
    }
}