import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import moment from 'moment';
import "@/design/index.scss";
const { format } = require('number-currency-format-2');

import Sparkline from 'vue-sparklines'

import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import AudioPlayer from '@liripeng/vue-audio-player';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);

Vue.use(BootstrapVue);

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(AudioPlayer);

Vue.filter('currencyFormat', function (amount) {

  return format(amount, {
    currency: 'XOF',
    thousandSeparator: ' ',
    showDecimals: 'IF_NEEDED'
  });
})
moment.locale('fr');

Vue.filter('defautTableDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY')
  }
});


Vue.filter('defautTableDateTIme', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY à HH:mm:ss')
  }
});

Vue.filter('defautTableDateTime2', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY à HH:mm')
  }
});

Vue.filter('etatByDate', function (debut, fin) {
  if (debut && fin == null) {
    let etat = 'Bientôt';
    if (new Date(debut).getTime() > new Date().getTime()) etat = 'Bientôt';
    if (new Date(debut).getTime() == new Date().getTime()) etat = 'En cours';
    if (new Date(debut).getTime() < new Date().getTime()) etat = 'Achevé';
    return etat;
  } 

  if (debut && fin) {
    let etat = 'Bientôt';
    if (new Date(debut).getTime() > new Date().getTime()) etat = 'Bientôt';
    if (new Date(debut).getTime() <= new Date().getTime() && new Date(fin).getTime() >= new Date().getTime()) etat = 'En cours';
    if (new Date(fin).getTime() < new Date().getTime()) etat = 'Achevé';
    return etat;
  }

})


Vue.filter('messageListDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD MMM HH:mm')
  }
});


Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
